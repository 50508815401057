import Glide from '@glidejs/glide';
import axios from 'axios';
import { FetchEventsCalendarPayload, EventCalendarCard, FetchEventsCalendarResponse } from './eventsCalendar.types';
import formatDateString from '../../../helpers/formatDate';
const eventsCalendar = document.querySelector('.events-calendar') as HTMLDivElement;

if (eventsCalendar) {
    const eventsCalendarCarousel = eventsCalendar.querySelector('.events-calendar__carousel-inner') as HTMLDivElement;
    const carouselItemsContainer = eventsCalendar.querySelector('.events-calendar__carousel-slides');
    const tabs: NodeListOf<HTMLButtonElement> = eventsCalendar.querySelectorAll('.events-calendar__tab');
    const columns: NodeListOf<HTMLDetailsElement> = eventsCalendar.querySelectorAll('.events-calendar__column');
    const eventsListSearchInput = eventsCalendar.querySelector('.events-calendar__search-input') as HTMLInputElement;
    const eventsCalendarDateFrom = eventsCalendar.querySelector('.date-range__input-from') as HTMLInputElement;
    const eventsCalendarDateTo = eventsCalendar.querySelector('.date-range__input-to') as HTMLInputElement;
    const direction: string = document.querySelector('html').getAttribute('dir');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let activeTab: number = 0;
    let activeItem: number = 0;

    let eventCalendarCards: EventCalendarCard[] = [];
    let keyword: string = '';
    let dateFrom: string = '';
    let dateTo: string = '';
    let eventType: number = 0;
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = 100;

    const createWeekButtons = (): void => {
        const oneDay = 24 * 60 * 60 * 1000;
        const today = new Date();

        const getStartOfCurrentWeek = (date: Date): Date => {
            const day = date.getDay();
            const diff = date.getDate() - day + (day >= 5 ? 6 : -1);
            return new Date(date.setDate(diff));
        };

        let currentDate = today.getDay() === 5 ? today : getStartOfCurrentWeek(today);

        for (let i = 0; i < 52; i++) {
            const startOfWeek = new Date(currentDate);
            const endOfWeek = new Date(startOfWeek.getTime() + 6 * oneDay);

            if (i === 0) {
                dateFrom = startOfWeek.toISOString().split('T')[0];
                dateTo = endOfWeek.toISOString().split('T')[0];
            }

            const button = document.createElement('button');
            button.className =
                'events-calendar__carousel-item events-calendar__carousel-item--active flex flex--align-center flex--justify-center';

            let startMonth: string = '';
            let endMonth: string = '';

            if (direction === 'rtl') {
                startMonth = startOfWeek.toLocaleDateString('ar', { month: 'long' });
                endMonth = endOfWeek.toLocaleDateString('ar', { month: 'long' });
            } else {
                startMonth = startOfWeek.toLocaleDateString('en-US', { month: 'long' });
                endMonth = endOfWeek.toLocaleDateString('en-US', { month: 'long' });
            }

            let buttonText = `${startOfWeek.getDate()}-${endOfWeek.getDate()}`;
            if (startMonth !== endMonth) {
                buttonText += ` ${startMonth}/${endMonth}`;
            } else {
                buttonText += ` ${startMonth}`;
            }

            button.textContent = buttonText;
            button.setAttribute('data-date-from', startOfWeek.toISOString().split('T')[0]);
            button.setAttribute('data-date-to', endOfWeek.toISOString().split('T')[0]);

            carouselItemsContainer.appendChild(button);

            currentDate = new Date(currentDate.getTime() + 7 * oneDay);
        }
    };

    const resetTabs = () => {
        tabs.forEach((tab) => tab.classList.remove('events-calendar__tab--active'));
    };

    const fetchEvents = async () => {
        const payload: FetchEventsCalendarPayload = {
            keyword,
            dateFrom,
            dateTo,
            eventType,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        try {
            let response;

            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchEventsCalendarResponse>('/api/events', payload);
            } else {
                response = await axios.post<FetchEventsCalendarResponse>('/api/events', payload);
            }
            eventCalendarCards = response.data.items;
        } catch (error) {
            console.error(error);
        } finally {
            updateCards(eventCalendarCards);
        }
    };

    const glideSettings = {
        type: 'slider',
        perView: 9,
        gap: 0,
        rewind: false,
        bound: false,
        direction: direction || 'ltr',
        breakpoints: {
            992: {
                perView: 6,
            },
            768: {
                perView: 5,
            },
            480: {
                perView: 3,
            },
        },
    };

    createWeekButtons();

    const glide = new Glide(eventsCalendarCarousel, glideSettings);

    glide.mount();

    const carouselItems: NodeListOf<HTMLButtonElement> = eventsCalendar.querySelectorAll(
        '.events-calendar__carousel-item',
    );

    carouselItems.forEach((item, index: number) => {
        item.addEventListener('click', () => {
            activeItem = index;
            glide.go(`=${index}`);

            if (index > 0) {
                resetTabs();
                tabs[1].classList.add('events-calendar__tab--active');
            }
        });
    });

    let timeout = null;
    glide.on('move.after', () => {
        activeItem === glide.index;

        if (glide.index > 0) {
            resetTabs();
            tabs[1].classList.add('events-calendar__tab--active');
            activeItem = glide.index;
        } else if (glide.index === 0) {
            resetTabs();
            tabs[0].classList.add('events-calendar__tab--active');
            activeItem = 0;
        }

        eventsListSearchInput.value = '';

        const carouselTabs: NodeListOf<HTMLButtonElement> = eventsCalendar.querySelectorAll(
            '.events-calendar__carousel-item',
        );

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            dateFrom = carouselTabs[activeItem].getAttribute('data-date-from');
            dateTo = carouselTabs[activeItem].getAttribute('data-date-to');
            fetchEvents();
        }, 100);
    });

    tabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();
            tabs[activeTab].classList.add('events-calendar__tab--active');

            if (index === 0) {
                glide.go(`=0`);
            }

            if (index === 1 && activeItem === 0) {
                activeItem = 1;
                glide.go(`=1`);
            }
        });
    });

    if (window.innerWidth < 992) {
        const eventsCalendarColumns: NodeListOf<HTMLDetailsElement> =
            eventsCalendar.querySelectorAll('.events-calendar__column');

        eventsCalendarColumns.forEach((column) => {
            column.removeAttribute('open');
            eventsCalendarColumns[0].open = true;
        });
    }

    // eventsCalendarDateFrom.addEventListener('change', () => {
    //     dateFrom = eventsCalendarDateFrom.value;
    //     setActiveCarouselTab(dateFrom);
    // });

    // eventsCalendarDateTo.addEventListener('change', () => {
    //     dateTo = eventsCalendarDateTo.value;
    //     setActiveCarouselTab(dateFrom);
    // });

    eventsListSearchInput.addEventListener('input', (e: Event) => {
        const target = e.target as HTMLInputElement;
        const searchInputValue = target.value.toLowerCase();
        const cards: NodeListOf<HTMLAnchorElement> = eventsCalendar.querySelectorAll('.events-calendar__card');

        cards.forEach((card: HTMLAnchorElement) => {
            const cardText = card.querySelector('.events-calendar__card-text').textContent?.toLowerCase() || '';

            if (cardText.includes(searchInputValue)) {
                card.classList.remove('hidden');
            } else {
                card.classList.add('hidden');
            }
        });
    });

    // const setMinMaxDateValues = () => {
    //     const today = new Date();
    //     const year = today.getFullYear();
    //     const month = (today.getMonth() + 1).toString().padStart(2, '0');
    //     const day = today.getDate().toString().padStart(2, '0');

    //     const formattedToday = `${year}-${month}-${day}`;

    //     eventsCalendarDateFrom.min = formattedToday;
    //     eventsCalendarDateTo.min = formattedToday;

    //     const nextYear = new Date(today);
    //     nextYear.setFullYear(today.getFullYear() + 1);

    //     const nextYearYear = nextYear.getFullYear();
    //     const nextYearMonth = (nextYear.getMonth() + 1).toString().padStart(2, '0');
    //     const nextYearDay = nextYear.getDate().toString().padStart(2, '0');

    //     const formattedNextYear = `${nextYearYear}-${nextYearMonth}-${nextYearDay}`;

    //     eventsCalendarDateTo.max = formattedNextYear;
    // };

    const updateCards = (items) => {
        columns.forEach((column) => {
            const cards = column.querySelectorAll('.events-calendar__card');
            cards.forEach((card) => card.remove());
        });

        items.forEach((item) => {
            createCard(item);
        });

        setColumnNumbers();
    };

    const createCard = (item) => {
        const card = document.createElement('a');
        card.className = 'events-calendar__card flex flex--column fade-in';
        card.setAttribute('href', item.link);
        card.setAttribute('data-from', item.start_Date);
        card.setAttribute('data-to', item.end_Date);

        card.innerHTML = `
        ${item.image !== null ? `<img src="${item.image}" alt="this is alt" class="events-calendar__card-image">` : ''}
        <p class="events-calendar__card-text">${item.title}</p>
        `;

        const dayToColumnIndex = {
            6: 0, // Saturday
            0: 1, // Sunday
            1: 2, // Monday
            2: 3, // Tuesday
            3: 4, // Wednesday
            4: 5, // Thursday
            5: -1, // Friday (excluded)
        };

        const dayOfWeek = new Date(item.start_Date).getDay();
        const columnIndex = dayToColumnIndex[dayOfWeek];

        if (columnIndex !== -1) {
            const cardsContainer = columns[columnIndex].querySelector('.events-calendar__cards') as HTMLDivElement;
            cardsContainer.appendChild(card);
        }
    };

    const setColumnNumbers = () => {
        columns.forEach((column) => {
            const numberContainer = column.querySelector('.events-calendar__column-number');
            const cardsContainer = column.querySelector('.events-calendar__cards');

            const numberOfCards = cardsContainer.children.length;
            let formattedNumber: string;

            if (numberOfCards === 0) {
                formattedNumber = '0';
            } else if (numberOfCards < 10) {
                formattedNumber = '0' + numberOfCards;
            } else {
                formattedNumber = numberOfCards.toString();
            }

            numberContainer.textContent = formattedNumber;
        });
    };

    // const setActiveCarouselTab = (date: string) => {
    //     const inputDate = new Date(date);
    //     const buttons = carouselItems;

    //     buttons.forEach((button, index) => {
    //         const dateFromStr = button.getAttribute('data-date-from');
    //         const dateToStr = button.getAttribute('data-date-to');

    //         if (dateFromStr && dateToStr) {
    //             const dateFrom = new Date(dateFromStr);
    //             const dateTo = new Date(dateToStr);

    //             if (inputDate >= dateFrom && inputDate <= dateTo) {
    //                 glide.go(`=${index}`);
    //             }
    //         }
    //     });
    // };

    // setMinMaxDateValues();
    fetchEvents();
}
