import {
    FetchAwardsListPayload,
    FetchAwardsListResponse,
    AwardsListCard,
    FetchAwardsCardSettingsResponse,
} from './awardsList.types';
import formatDateString from '../../../helpers/formatDate';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';

const awardsAccreditations = document.querySelector('.awards-list') as HTMLDivElement;

if (awardsAccreditations) {
    const eventTabs: NodeListOf<HTMLButtonElement> = awardsAccreditations.querySelectorAll('.awards-list__tab');

    const awardsGrid = awardsAccreditations.querySelector('.awards-list__grid') as HTMLDivElement;
    const awardsFilters = awardsAccreditations.querySelector('.awards-list__search') as HTMLDivElement;
    const awardsListSearchInput = awardsAccreditations.querySelector('.awards-list__search-input') as HTMLInputElement;
    const nextPageButton = awardsAccreditations.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = awardsAccreditations.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = awardsAccreditations.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = awardsAccreditations.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = awardsAccreditations.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = awardsAccreditations.querySelector('.pagination') as HTMLDivElement;
    const awardsTabsComponent = awardsAccreditations.querySelector('.awards-list__tabs') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = awardsAccreditations.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let activeTab: number = 0;

    let awardsCards: AwardsListCard[] = [];
    let keyword: string = '';
    let awardsType: number = 2;
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    let awardsCardSettings: FetchAwardsCardSettingsResponse = {
        Text: '',
    };

    const resetTabs = () => {
        eventTabs.forEach((tab) => tab.classList.remove('awards-list__tab--active'));
    };

    const fetchAwardsCardSettings = async () => {
        try {
            const response = await axios.get<FetchAwardsCardSettingsResponse>(
                `/api/awardsaccreditations/cardsettings?currentCulture=${currentCulture}`
            );

            awardsCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAwards = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchAwardsListPayload = {
            keyword,
            awardsType,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchAwardsListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/awardsaccreditations',
                    payload
                );
            } else {
                response = await axios.post<FetchAwardsListResponse>('/api/awardsaccreditations', payload);
            }
            awardsCards = response.data.items;
            if (awardsCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                awardsCards,
                fetchAwards
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        awardsGrid.innerHTML = '';

        awardsCards.forEach((card: AwardsListCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'awards-list__card flex flex--column';

            cardElement.innerHTML = `        
					<div class="awards-list__card-image-container">
						<img src="${card.image}" alt="${card.title}" class="awards-list__card-image">
					</div>
                    ${
                        card.date.slice(0, 10) === '0001-01-01'
                            ? ''
                            : `<div class='awards-list__card-date flex flex--align-center'>
                                    <img src='/assets/img/img/calendar-icon-yellow.svg' alt='calendar icon' class='awards-list__card-icon' />
                                    <span class='awards-list__card-date-text'>
                                    ${formatDateString(card.date)}
                                    </span>
                                </div>`
                    }
                    <h4 class="awards-list__card-title">${card.title}</h4>
                    <p class="awards-list__card-text">${card.content}</p>
      `;

            awardsGrid.appendChild(cardElement);
        });
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        awardsTabsComponent.classList.add('disabled');
        awardsFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        awardsTabsComponent.classList.remove('disabled');
        awardsFilters.classList.remove('disabled');
    };

    eventTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();
            eventTabs[activeTab].classList.add('awards-list__tab--active');
            pageNumber = 1;

            if (index === 0) {
                awardsType = 2;
            } else {
                awardsType = 1;
            }

            fetchAwards();
        });
    });

    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchAwards();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchAwards();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchAwards();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchAwards();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchAwards();
    });

    let timeout = null;
    awardsListSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = awardsListSearchInput.value.trim();
            pageNumber = 1;
            fetchAwards();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = Latest
        // 2 = Oldest

        radio.addEventListener('change', () => {
            const checkedRadio = awardsAccreditations.querySelector(
                'input[name="sort-filter"]:checked'
            ) as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchAwards();
        });
    });

    fetchAwardsCardSettings();
    fetchAwards();
}
